<template>
  <div>
    <div v-for="item in childData" :key="item.key">
      <div
        v-show="item.isShow"
        class="tag-box"
        v-if="item.hasQuery !== false || item.hasSdv || item.hasReview"
      >
        <van-icon
          size="14px"
          v-if="item.hasQuery !== false"
          :color="['red', '#ff8917', '#1989fa'][item.hasQuery]"
          name="question"
          @click="onQueryClick(item)"
          style="margin-left: 5px"
        />
        <van-icon
          v-if="item.hasSdv"
          style="margin-left: 5px"
          size="13px"
          :name="wpexplorer"
        />
        <van-icon
          style="margin-left: 5px"
          v-if="item.hasReview"
          size="13px"
          :name="preview_2"
        />
      </div>
      <!-- input -->
      <van-field
        :label-width="labelWidth"
        v-if="item.type === 'input'"
        :name="item.key"
        :label="item.title"
        v-model="item.value"
        center
        :readonly="item.isReadonly"
        :disabled="item.isDisable"
        :onChange="onChange(item.key)"
        v-show="item.isShow"
        :placeholder="item.isReadonly ? '' : '请输入'"
        :clearable="canClearable"
      >
        <template slot="left-icon" v-if="item.isReview == 1 || item.isSdv == 1">
          <input
            ref="check-input"
            @click.stop="onClickInput"
            type="checkbox"
            v-model="checked"
            name="checkbox"
            :value="item.key"
          />
        </template>
        <template slot="right-icon">
          <div class="right-icon-box" @click.stop>
            <van-popover
              v-if="item.isUnit != 1 && item.canQuery"
              v-model="item.showSettingPopover"
              trigger="click"
              :actions="getActions(item)"
              @select="(val) => onSelect(val, item)"
              placement="bottom-end"
            >
              <template #reference>
                <van-icon name="setting" />
              </template>
            </van-popover>
          </div>
        </template>
      </van-field>
      <!-- textarea -->
      <van-field
        :label-width="labelWidth"
        v-else-if="item.type === 'textarea'"
        :name="item.key"
        type="textarea"
        :label="item.title"
        v-model="item.value"
        center
        :placeholder="item.isReadonly ? '' : '请输入'"
        :disabled="item.isDisable"
        :readonly="item.isReadonly"
        :onChange="onChange(item.key)"
        v-show="item.isShow"
        :clearable="canClearable"
        rows="3"
      >
        <template slot="left-icon" v-if="item.isReview == 1 || item.isSdv == 1">
          <input
            @click.stop="onClickInput"
            type="checkbox"
            v-model="checked"
            name="checkbox"
            :value="item.key"
            ref="check-input"
          />
        </template>
        <template slot="right-icon">
          <div class="right-icon-box" @click.stop>
            <van-popover
              v-if="item.isUnit != 1 && item.canQuery"
              v-model="item.showSettingPopover"
              trigger="click"
              :actions="getActions(item)"
              @select="(val) => onSelect(val, item)"
              placement="bottom-end"
            >
              <template #reference>
                <van-icon name="setting" />
              </template>
            </van-popover>
          </div>
        </template>
      </van-field>
      <!-- date -->
      <van-field
        v-else-if="item.type === 'date'"
        :label-width="labelWidth"
        readonly
        clickable
        name="calendar"
        center
        :value="item.value"
        :label="item.title"
        placeholder="点击选择日期"
        @click="
          showDataFun(item.key, item.isDisable, item.value, item.isCanUK, item)
        "
        :onChange="onChange(item.key)"
        :disabled="item.isDisable"
        v-show="item.isShow"
        :clearable="canClearable"
      >
        <template slot="left-icon" v-if="item.isReview == 1 || item.isSdv == 1">
          <input
            type="checkbox"
            v-model="checked"
            name="checkbox"
            :value="item.key"
            @click.stop="onClickInput"
            ref="check-input"
          />
        </template>
        <template slot="right-icon">
          <div class="right-icon-box" @click.stop>
            <van-icon
              v-if="item.value && canClearable"
              class="clear-btn"
              color="#ddd0d0"
              @click.stop="obClearClick(item)"
              name="clear"
            />
            <van-popover
              v-if="item.isUnit != 1 && item.canQuery"
              v-model="item.showSettingPopover"
              trigger="click"
              :actions="getActions(item)"
              @select="(val) => onSelect(val, item)"
              placement="bottom-end"
            >
              <template #reference>
                <van-icon name="setting" />
              </template>
            </van-popover>
          </div>
        </template>
      </van-field>
      <!-- 
        radio_h
        radio_v
        checkbox_h
        checkbox_v
       -->
      <van-field
        v-else-if="
          item.type === 'radio_h' ||
          item.type === 'radio_v' ||
          item.type === 'checkbox_v' ||
          item.type === 'checkbox_h'
        "
        :name="item.key"
        :label="item.title"
        :label-width="labelWidth"
        v-show="item.isShow"
        center
        :clearable="canClearable"
        :disabled="item.isDisable"
      >
        <template #input>
          <van-radio-group
            v-if="item.type === 'radio_h'"
            :value="item.value"
            :onChange="onChange(item.key)"
            direction="horizontal"
            :disabled="item.isDisable"
          >
            <van-radio
              v-for="dict in item.dictList"
              :name="dict.id"
              :key="dict.id"
              @click="() => radioClick(dict.id, item)"
              >{{ dict.name }}</van-radio
            >
          </van-radio-group>
          <van-radio-group
            :value="item.value"
            v-else-if="item.type == 'radio_v'"
            :onChange="onChange(item.key)"
            direction="vertical"
            :disabled="item.isDisable"
          >
            <van-radio
              v-for="dict in item.dictList"
              :name="dict.id"
              :key="dict.id"
              @click="() => radioClick(dict.id, item)"
              >{{ dict.name }}</van-radio
            >
          </van-radio-group>
          <van-checkbox-group
            v-else-if="item.type === 'checkbox_v'"
            v-model="item.value"
            :disabled="item.isDisable"
            :onChange="onChange(item.key)"
          >
            <van-checkbox
              shape="square"
              v-for="dict in item.dictList"
              :name="dict.id"
              :key="dict.id"
              >{{ dict.name }}</van-checkbox
            >
          </van-checkbox-group>
          <van-checkbox-group
            v-else-if="item.type === 'checkbox_h'"
            v-model="item.value"
            :disabled="item.isDisable"
            direction="horizontal"
            :onChange="onChange(item.key)"
          >
            <van-checkbox
              shape="square"
              v-for="dict in item.dictList"
              :name="dict.id"
              :key="dict.id"
              >{{ dict.name }}</van-checkbox
            >
          </van-checkbox-group>
        </template>
        <template slot="left-icon" v-if="item.isReview == 1 || item.isSdv == 1">
          <input
            type="checkbox"
            v-model="checked"
            name="checkbox"
            :value="item.key"
            @click.stop="onClickInput"
            ref="check-input"
          />
        </template>
        <template slot="right-icon">
          <div class="right-icon-box" @click.stop>
            <van-popover
              v-if="item.isUnit != 1 && item.canQuery"
              v-model="item.showSettingPopover"
              trigger="click"
              :actions="getActions(item)"
              @select="(val) => onSelect(val, item)"
              placement="bottom-end"
            >
              <template #reference>
                <van-icon name="setting" />
              </template>
            </van-popover>
          </div>
        </template>
      </van-field>
      <!-- time -->
      <van-field
        v-show="item.isShow"
        readonly
        clickable
        v-else-if="item.type === 'time'"
        :name="item.key"
        :label="item.title"
        :value="item.value"
        placeholder="请选择时间"
        :label-width="labelWidth"
        :onChange="onChange(item.key)"
        @click="onDatetimeShow(item.key, item.isDisable, item.type, item)"
        :clearable="canClearable"
        :disabled="item.isDisable"
      >
        <template slot="left-icon" v-if="item.isReview == 1 || item.isSdv == 1">
          <input
            type="checkbox"
            v-model="checked"
            name="checkbox"
            :value="item.key"
            @click.stop="onClickInput"
            ref="check-input"
          />
        </template>
        <template slot="right-icon">
          <div class="right-icon-box" @click.stop>
            <van-icon
              v-if="item.value && canClearable"
              class="clear-btn"
              color="#ddd0d0"
              @click.stop="obClearClick(item)"
              name="clear"
            />
            <van-popover
              v-if="item.isUnit != 1 && item.canQuery"
              v-model="item.showSettingPopover"
              trigger="click"
              :actions="getActions(item)"
              @select="(val) => onSelect(val, item)"
              placement="bottom-end"
            >
              <template #reference>
                <van-icon name="setting" />
              </template>
            </van-popover>
          </div>
        </template>
      </van-field>
      <!-- selectS -->
      <van-field
        readonly
        clickable
        center
        v-show="item.isShow"
        v-else-if="item.type === 'selectS'"
        :name="item.key"
        :label="item.title"
        :value="item.value"
        :label-width="labelWidth"
        :placeholder="item.isReadonly ? '' : '请选择'"
        :onChange="onChange(item.key)"
        @click="onDatetimeShow(item.key, item.isDisable, item.type, item)"
        :clearable="canClearable"
      >
        <template slot="left-icon" v-if="item.isReview == 1 || item.isSdv == 1">
          <input
            ref="check-input"
            type="checkbox"
            v-model="checked"
            name="checkbox"
            :value="item.key"
            @click.stop="onClickInput"
          />
        </template>
        <template slot="right-icon">
          <div class="right-icon-box" @click.stop>
            <van-icon
              v-if="item.value && canClearable"
              class="clear-btn"
              color="#ddd0d0"
              @click.stop="obClearClick(item)"
              name="clear"
            />
            <van-popover
              v-if="item.isUnit != 1 && item.canQuery"
              v-model="item.showSettingPopover"
              trigger="click"
              :actions="getActions(item)"
              @select="(val) => onSelect(val, item)"
              placement="bottom-end"
            >
              <template #reference>
                <van-icon name="setting" />
              </template>
            </van-popover>
          </div>
        </template>
      </van-field>
    </div>
    <slot name="button"></slot>
    <van-popup v-model="showPicker" @closed="onCancelPicker" position="bottom">
      <!-- <van-datetime-picker
        v-if="showTime"
        type="time"
        @confirm="onDatetimeConfirm"
        @cancel="onCancelPicker"
      >
        <template #columns-top>
          <input v-if="isCanUK" class="unKnow-input" v-model="unKnowValue" />
        </template>
      </van-datetime-picker> -->
      <van-picker
        v-if="showTime"
        show-toolbar
        :columns="timeColumns"
        @confirm="onDatetimeConfirm"
        @cancel="onCancelPicker"
        @change="onTimePickerChange"
      >
        <template #columns-top>
          <input v-if="isCanUK" class="unKnow-input" v-model="unKnowValue" />
        </template>
      </van-picker>
      <van-picker
        v-else-if="showSelsct"
        show-toolbar
        name="text"
        :columns="selectColumns"
        @confirm="onSelsctConfirm"
        @cancel="onCancelPicker"
        @change="onTextPickerChange"
      >
        <template #columns-top>
          <input class="unKnow-input" v-model="otherSelect" />
        </template>
      </van-picker>
      <van-datetime-picker
        v-else-if="showData"
        v-model="currentDate"
        :type="dataType"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirm"
        @cancel="onCancelPicker"
        @change="onYearPickerChange"
      >
        <template #columns-top>
          <input v-if="isCanUK" class="unKnow-input" v-model="unKnowValue" />
        </template>
      </van-datetime-picker>
    </van-popup>
  </div>
</template>

<script>
import {
  Field,
  Switch,
  Calendar,
  Cell,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  Popup,
  DatetimePicker,
  Picker,
  Icon,
  Popover,
} from "vant";
import { deepCopy } from "../js/utils/utils";
import wpexplorer from "../assets/wpexplorer-o2.png";
import preview_2 from "../assets/preview2.png";

function getArrStrbyNum(num) {
  let arr = [];
  for (let i = 0; i < num; i++) {
    let j = i < 10 ? "0" + i : i.toString();
    arr.push(j);
  }
  return arr;
}

export default {
  name: "rh-form",
  components: {
    [Field.name]: Field,
    [Switch.name]: Switch,
    [Calendar.name]: Calendar,
    [Cell.name]: Cell,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Picker.name]: Picker,
    [Icon.name]: Icon,
    [Popover.name]: Popover,
  },
  props: {
    formChild: {
      type: Array,
      default: () => [],
    },
    index: {
      type: Number,
      default: 0,
    },
    isCheckAll: {
      type: Boolean,
      default: false,
    },
    queryAdd: {
      type: String || Number,
      default: "0",
    },
    labelWidth: {
      type: Number,
      default: 175,
    },
    canClearable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    timeColumns() {
      let arr = [];
      if (this.timeType.includes("h")) {
        arr.push({
          values: getArrStrbyNum(24),
          defaultIndex: 0,
        });
      }
      if (this.timeType.includes("m")) {
        arr.push({
          values: getArrStrbyNum(60),
          defaultIndex: 0,
        });
      }
      if (this.timeType.includes("s")) {
        arr.push({
          values: getArrStrbyNum(60),
          defaultIndex: 0,
        });
      }

      return arr;
    },
  },
  data() {
    return {
      switchChecked: false,
      showData: false,
      tempKey: "",
      childData: null,
      showPicker: false,
      showSelsct: false,
      showTime: false,
      selectColumns: [],
      checked: [],
      notChecked: [],
      maxDate: new Date(),
      minDate: new Date(1900, 0, 1),
      wpexplorer: wpexplorer,
      preview_2: preview_2,
      currentDate: new Date(),
      unKnowValue: "",
      isCanUK: false,
      otherSelect: "",
      dataType: "",
      timeType: "",
    };
  },
  mounted() {
    this.childData = deepCopy(this.formChild);
    this.childData.forEach((item) => {
      if ((item.isReview && item.hasReview) || (item.isSdv && item.hasSdv)) {
        this.onClickInput({
          target: {
            value: item.key,
          },
        });
      }
    });
  },
  watch: {
    formChild() {
      this.childData = deepCopy(this.formChild);
    },
  },
  methods: {
    onConfirm(date) {
      let j = this.childData.findIndex((item) => item.key === this.tempKey);
      if (this.unKnowValue) {
        this.childData[j].value = this.unKnowValue;
      } else {
        this.childData[j].value = this.formatDate(date);
      }
      this.onCancelPicker();
    },
    formatDate(date) {
      let month =
        date.getMonth() + 1 >= 10
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1);
      let day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
      return `${date.getFullYear()}-${month}-${day}`;
    },
    showDataFun(key, disable, value, isCanUK, item) {
      if (disable) {
        return;
      }
      let data_format = item.dataFormat;
      let reg = /(yyyy)|(mm)|(dd)/gi;
      // console.log(data_format.match(reg));
      let dataType = [];
      let arr = data_format.match(reg);
      arr.forEach((item) => {
        if (item) {
          item = item.toLowerCase();
          if (item === "yyyy") {
            dataType.push("year");
          } else if (item === "mm") {
            dataType.push("month");
          } else if (item === "dd") {
            dataType.push("day");
          }
        }
      });
      dataType = dataType.join("-");
      if (dataType === "year-month-day") {
        dataType = "date";
      }
      this.dataType = dataType;
      if (value) {
        let value1 = value.replace(/-/g, "/");
        let time = new Date(value1);
        if (time == "Invalid Date") {
          this.unKnowValue = value;
          this.currentDate = "";
        } else {
          this.currentDate = new Date(value1);
          this.unKnowValue = "";
        }
      }
      this.isCanUK = isCanUK;
      this.showData = true;
      this.showPicker = true;
      this.tempKey = key;
    },
    onChange(key) {
      let i = this.childData.findIndex((item) => item.key === key);
      let j = this.formChild.findIndex((item) => item.key === key);
      let value1 = this.childData[i].value;
      let value2 = this.formChild[j].value;
      if (JSON.stringify(value1) !== JSON.stringify(value2)) {
        this.$nextTick(() => {
          this.$emit("dataChange", this.childData, this.index);
        });
      }
    },
    onDatetimeShow(key, disable, type, item) {
      if (disable) {
        return;
      }
      if (type == "time") {
        let dataFormat = item.dataFormat;
        let timeType = [];
        let reg = /(HH)|(mm)|(ss)/gi;
        let arr = dataFormat.match(reg);
        arr.forEach((item) => {
          item = item.toLowerCase();
          if (item === "hh") {
            timeType.push("h");
          } else if (item === "mm") {
            timeType.push("m");
          } else if (item === "ss") {
            timeType.push("s");
          }
        });
        this.timeType = timeType.join("");
        this.showTime = true;
        this.showSelsct = false;
        let value = item.value;
        if (value) {
          let time = new Date("2022/01/01 " + value);
          if (time == "Invalid Date") {
            this.unKnowValue = value;
          }
        }
        this.isCanUK = item.isCanUK;
      } else if (type == "selectS") {
        this.showSelsct = true;
        this.showTime = false;
        let value = item.value;
        if (value && !item.selectColumns.includes(value)) {
          this.otherSelect = value;
        }
        this.selectColumns = item.selectColumns;
      }
      this.showPicker = true;
      this.tempKey = key;
    },
    onDatetimeConfirm(times) {
      // console.log(times);
      let time = times.join(":");
      let j = this.childData.findIndex((item) => item.key === this.tempKey);
      if (this.unKnowValue) {
        this.childData[j].value = this.unKnowValue;
      } else {
        this.childData[j].value = time;
      }
      this.onCancelPicker();
    },
    onCancelPicker() {
      this.showPicker = false;
      this.showSelsct = false;
      this.showTime = false;
      this.showData = false;
      this.isCanUK = false;
      this.unKnowValue = "";
      this.otherSelect = "";
    },
    onSelsctConfirm(value) {
      let j = this.childData.findIndex((item) => item.key === this.tempKey);
      if (this.otherSelect) {
        this.childData[j].value = this.otherSelect;
      } else {
        this.childData[j].value = value;
      }
      this.onCancelPicker();
    },
    checkAll() {
      let checkInputs = this.$refs["check-input"];
      if (this.isCheckAll) {
        checkInputs.forEach((item) => {
          if (!this.checked.includes(item.value)) {
            item.click();
          }
        });
      } else {
        checkInputs.forEach((item) => {
          if (this.checked.includes(item.value)) {
            item.click();
          }
        });
      }
    },
    onClickInput(e) {
      let value = e.target.value;
      let index = this.checked.indexOf(value);
      if (index > -1) {
        let item = this.checked.splice(index, 1);
        this.notChecked.push(item[0]);
      } else {
        this.checked.push(value);
        let i = this.notChecked.indexOf(value);
        if (i > -1) {
          this.notChecked.splice(i, 1);
        }
      }
      let arr = this.childData.filter(
        (item) => item.isReview == 1 || item.isSdv == 1
      );
      if (this.checked.length === arr.length && this.checked.length > 0) {
        this.$emit("isCheckAllChange", true, this.checked, this.index);
      } else {
        this.$emit("isCheckAllChange", false, this.checked, this.index);
      }
      this.$emit("notCheckedEvent", this.notChecked);
    },
    onCreatQueryClick(obj) {
      this.$emit("creatQueryClick", obj);
    },
    onSelect(action, item) {
      console.log(action);
      if (action.text == "质疑") {
        this.onCreatQueryClick(item);
      }
      item.showSettingPopover = false;
    },
    getActions(item) {
      let arr = [];
      if (this.queryAdd == 1 && item.canQuery) {
        arr.push({ text: "质疑" });
      } else {
        arr.push({ text: "质疑", disabled: true });
      }
      return arr;
    },
    radioClick(value, item) {
      if (item.isDisable) {
        return;
      }
      if (item.value == value) {
        item.value = "";
      } else {
        item.value = value;
      }
    },
    obClearClick(item) {
      if (item.isDisable || item.isReadonly) {
        return;
      }
      item.value = "";
    },
    onYearPickerChange(e) {
      let values = e.getValues();
      if (this.isCanUK) {
        this.unKnowValue = values.join("-");
      }
    },
    onTimePickerChange(e) {
      let values = e.getValues();
      if (this.isCanUK) {
        this.unKnowValue = values.join(":");
      }
    },
    onTextPickerChange(e) {
      let values = e.getValues();
      this.otherSelect = values[0];
    },
    onQueryClick(item) {
      // console.log(item);
      // this.$router.push({
      //   path: "/queryList",
      // });
      this.$emit("queryClick", item);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../style/index.scss";
.tag-box {
  width: 100%;
  background-color: #fff;
  padding-left: 5px;
  padding-top: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.unKnow-input {
  width: 90%;
  height: 50px;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid $primary-color;
  box-sizing: border-box;
  display: block;
  margin: auto;
}
.van-field {
  border-bottom: 1px solid #f1f1f1;
}
.right-icon-box {
  position: relative;
  width: 10px;
  height: 24px;
}
.clear-btn {
  position: absolute;
  left: -24px;
}
::v-deep .van-ellipsis {
  overflow: visible;
  white-space: pre-wrap;
}
</style>