let roleName, subjectReview, subjectSdv;
export function typeOf(obj) {
    const toString = Object.prototype.toString;
    const map = {
        '[object Boolean]': 'boolean',
        '[object Number]': 'number',
        '[object String]': 'string',
        '[object Function]': 'function',
        '[object Array]': 'array',
        '[object Date]': 'date',
        '[object RegExp]': 'regExp',
        '[object Undefined]': 'undefined',
        '[object Null]': 'null',
        '[object Object]': 'object'
    };
    return map[toString.call(obj)];
}

const TYPES = {
    text: "input",
    long_text: "textarea",
    date: "date",
    time: "time",
    select_text: "selectS",
    radio_horizontal: "radio_h",
    radio_vertical: "radio_v",
    checkbox_horizontal: "checkbox_h",
    checkbox_vertical: "checkbox_v",
}

export function handleValues(field, values = {}) {
    let val = Object.keys(values).length > 0 && values.is_temp_data === 0;
    let type = TYPES[field.control_type];
    if (type === 'selectS' && field.default_value === '') {
        type = 'input'
    }
    if (type === 'input' && typeof field.default_value == 'string' && field.default_value.includes(',')) {
        type = 'selectS'
    }
    let value = Object.hasOwn(values, field.field_oid) ? values[field.field_oid] : (type.includes('checkbox') ? [] : field.default_value);
    if (value === '' && type.includes('checkbox')) {
        value = [];
    }
    let isUnit = field.is_unit;
    if (isUnit == 1) {
        value = field.default_value;
    }
    if (typeOf(value) == 'string' && value.includes(',')) {
        let a = value.split(',');
        value = a[0]
    }
    let reviewGroups = field.review_groups && field.review_groups.split(',') || [];
    let isReview = 0
    if (reviewGroups.includes(roleName) && val && field.is_review == 1 && subjectReview == '1') {
        isReview = 1;
    }
    return {
        key: '$' + field.field_oid,
        title: field.field_label,
        value: value,
        type: type,
        dictList: (field.data_dictionary || []).map(dict => {
            return {
                name: dict.dic_label,
                id: dict.dic_code
            }
        }),
        isReadonly: field.is_readonly != '0',
        isDisable: false,
        isShow: true,
        selectColumns: field.default_value.length ? field.default_value.split(',').filter(item => item !== '') : [],
        isReview: isReview,
        isSdv: val && subjectSdv == '1' && field.is_sdv == 1 ? 1 : 0,
        canQuery: val ? true : false,
        hasQuery: values.query && Object.hasOwn(values.query, field.field_oid) ? values.query[field.field_oid] : false,
        hasReview: values.review && values.review[roleName] && values.review[roleName][field.field_oid] == '1' ? true : false,
        hasSdv: values.sdv && values.sdv[field.field_oid] == '1' ? true : false,
        isCanUK: field.data_format && field.data_format.includes('-'),
        isUnit: isUnit,
        dataFormat: field.data_format,
    }
}

//处理普通表单
export function common(fields, form_info, form_values) {
    let _formList = [];
    let _firstArray = true; //是否是第一次创建数据  遇到表单类型  设置位true
    let _currChilds; //当前数据对象
    const values = form_values[0];
    let _id = 0;

    for (let field of fields) {
        if (Array.isArray(field)) {
            _formList.push({
                id: _id++,
                childs: field.map((f, i) => {
                    if (i == 0) {
                        let field_label = f.field_label;
                        if (field_label.includes('_')) {
                            let arr = field_label.split('_');
                            // console.log(arr);
                            f.field_label = arr[arr.length - 1];
                            f.is_readonly = '1';
                        }
                    }
                    return handleValues(f, values);
                }),
                is_temp_data: values && Object.hasOwn(values, 'is_temp_data') ? values.is_temp_data : 1
            })
        } else {
            const _child = handleValues(field, values);
            if (_firstArray) {
                _firstArray = false;
                _currChilds = [_child];
                _formList.push({
                    id: _id++,
                    childs: _currChilds,
                    is_temp_data: values && Object.hasOwn(values, 'is_temp_data') ? values.is_temp_data : 1
                })
            } else {
                _currChilds.push(_child);
            }
        }
    }
    return _formList
}

//处理日志表单
export function log(fields, form_info, form_values) {
    let _formList = [];
    let _id = 0;
    if (form_values.length == 0) {
        form_values.push({});
    }
    for (let values of form_values) {
        _formList.push({
            id: _id++,
            childs: fields.map(field => {
                return handleValues(field, values);
            }),
            is_temp_data: values && Object.hasOwn(values, 'is_temp_data') ? values.is_temp_data : 1,
            subject_item_id: values && Object.hasOwn(values, 'subject_item_id') ? values.subject_item_id : '',
        })
    }
    return _formList
}

/**
 * 处理EDC表单数据
 */
export function handleEdcFormData(res, role_name, subject_review, subject_sdv) {
    const {
        fields = [],
        form_info,
        form_values
    } = res;
    roleName = role_name;
    subjectReview = subject_review;
    subjectSdv = subject_sdv;
    if (!form_info.is_lab && !form_info.is_log) {
        //普通表单
        return common(fields, form_info, form_values)
    } else if (form_info.is_lab && form_info.is_log) {
        if (form_values.length === 0) {
            form_values.push({});
        }
        return form_values.map(values => {
            return {
                array: common(fields, form_info, [values]),
                is_temp_data: values && Object.hasOwn(values, 'is_temp_data') ? values.is_temp_data : 1,
                subject_item_id: values && Object.hasOwn(values, 'subject_item_id') ? values.subject_item_id : '',
            }
        });
    } else if (form_info.is_lab) {
        //实验室表单
        return common(fields, form_info, form_values)
    } else {
        //log表单
        return log(fields, form_info, form_values)
    }
}

export function handleFormParams(folder, form) {
    return {
        folder_oid: folder.folder_oid,
        form_oid: form.form_oid,
        folder_name: folder.folder_name,
        form_name: form.form_name,
        folder_form_id: form.folder_form_id,
        subject_item_id: form.subject_item_id,
        disabled: !form.is_active
    }
}

export function handleFormClass(form) {
    // 是否激活
    if (!form.is_active) {
        return "disabled"
    }

    // 是否有数据
    if (form.subject_item_id) {
        return "has-data"
    }
}


// 深拷贝
export function deepCopy(data) {
    const t = typeOf(data);
    let o;

    if (t === 'array') {
        o = [];
    } else if (t === 'object') {
        o = {};
    } else {
        return data;
    }

    if (t === 'array') {
        for (let i = 0; i < data.length; i++) {
            o.push(deepCopy(data[i]));
        }
    } else if (t === 'object') {
        for (let i in data) {
            o[i] = deepCopy(data[i]);
        }
    }
    return o;
}
